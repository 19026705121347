// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
.native-input.sc-ion-input-md,
.native-textarea.sc-ion-textarea-md,
ion-datetime {
    padding-left: 8px;
}

.d-flex {
    display: flex;
}

.h-100 {
    height: 100%;
}

ion-content, ion-item, .scanner {
    --background: #F4F5F9;
    background: #F4F5F9 !important;
}

ion-button {
    text-transform: inherit;
}

.alert-button-inner {
    text-transform: capitalize;
}

.domainImage {
    display: block;
    max-height: 100px;
    margin: 0 auto;
}

ion-select {
    width: 100%;
    min-width: 100%;
    padding-left: 8px!important;
}

.read-only {
    pointer-events: none;
}

.checkbox-label {
    margin: 27px 0;
}

.item-label .label-floating {
    opacity: 0.5;
}

.scan-text {
    width: 40px;
}

.info-alert {
    z-index: 99999999 !important;
}

.question-body {
    margin: 10px 16px;
    border-radius: 25px;
    --padding-start: 10px;
    --background: transparent;
}

.about-info .alert-sub-title {
    font-weight: 500 !important;
}

.correctAnswer {
    background: #0ec254 !important;
    --background: #0ec254 !important;
    color: white;
}

.wrongAnswer {
    background: #f04141 !important;
    --background: #f04141 !important;
    color: white;
}

.disabledAnswer {
    pointer-events: none;
}

.mic-button {
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.mic-button ion-icon {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.54) !important;
}

.alert-message {
    color: black !important;
}

.third-party-header{
    font-size: 20px !important;
    font-weight: 500 !important;
}

ion-menu-toggle {
    ion-item {
        --padding-start: 16px;
    --padding-end: 16px;
    }
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
}

// .label-floating.sc-ion-label-md-h {
//     // transform: translateY(140%);
// }

// .searchbar-search-icon {
//     top: 19px !important;
// }

zxing-scanner video {
    height: calc(100vh - 111px) !important;
}
.searchbar-home {
  height: 48px;
    --background: white;
    border-radius: 100px;
    --border-radius: 100px;
    box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #00000033, 0 1px 5px 0 #0000001f;
    --color: var(--ion-color-step-850, #262626);

    .search-icon {
        font-size: 20px;
        margin-right: 10px;
        cursor: pointer;
        padding-left: 16px;
    }
    .close-icon, .mic-icon {
        cursor: pointer;
    }
}

.searchbar {
    --background: white;
    border-radius: 100px;
    --border-radius: 100px;
    box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #00000033, 0 1px 5px 0 #0000001f;
    --color: var(--ion-color-step-850, #262626);

    .search-icon {
        font-size: 20px;
        margin-right: 10px;
        cursor: pointer;
        padding-left: 16px;
    }
    .close-icon, .mic-icon {
        cursor: pointer;
    }
    ion-input {
        --padding-top: 0px
    }
}

.header-row {
    background: #f7f7f7;
    box-shadow: none;
    --ion-padding:0rem;
    .fixed-top-content{
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        padding: 0 8px;
        // background: var(--ion-color-secondary);
    }
}

.bg-white {
    background: white !important;
}

.header-menu {
    --min-height:unset;
    // ion-menu-button, ion-button {
    //     width: 3rem !important;
    //     height: 3rem !important;
    //     font-size: 30px !important;
    // }

    ion-title{
      padding-top: 10px;
      padding-bottom: 10px;
        ion-grid{
            padding:0;
            ion-row{
                padding: 0 !important;
                justify-content: start;
            }
            ion-col{
                padding: 0 !important;
                display: flex;
                align-items: center;
                max-height: 3rem;
            }
        }
        img{
            display:inline-block;
            max-height:100%;
            max-width: 150px;
        }
    }

    ion-icon {
        font-size: 30px !important;
    }
}

.video-call {
    .modal-wrapper {
        background: #414143;
    }
    .caller-icon {
        font-size: 150px;
    }
    .control-buttons {
        padding: 10px 0;
        ion-button {
            --border-radius: 50%;
            height: 50px;
            width: 50px;
            --padding-start: 2px;
            --padding-end: 2px;
            --padding-top: 2px;
            --padding-bottom: 2px;
           margin: 10px;
        }

        ion-icon {
            font-size: 30px;
        }
    }

    .control-buttons::before {
        background-image: none !important;
    }
}

.caller-container {
    position: absolute;
    bottom: 100px;
    right: 20px;
    height: 100px;
}

.participant-container {
    position: absolute;
}

.ion-hide {
    display: none;
}

.ion-show {
    display: block;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .image-container {
        min-height: 100px !important;
        border-radius: 10px;
    }
    .item-col {
        flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    }
    .login-container {
        min-width: auto;
    }

    .login-onetime-container{
        min-width: auto;
    }
    .onetime-main-tile{
        background-color: white;
        min-height: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        border-radius: 10px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .image-container {
        min-height: 200px;
        border-radius: 10px;
    }
    .item-col {
        flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    }
    .login-container {
        min-width: 600px;
        max-width: 600px !important;
    }
    .onetime-main-tile{
        background-color: white;
        min-height: 85%;
        margin-top: 60px;
        margin-bottom: 60px;
        border-radius: 10px;
    }
    .login-onetime-container {
        min-width: 600px;
        max-width: 600px !important;
    }

}

@media only screen and (max-width: 769px) {
    .question-modal {
        --width: 100% !important;
        --height: 100% !important;
    }
    .ion-hide-sm-up {
        display: none;
    }
    .ion-hide-sm-down {
        display: block;
    }
    .ion-hide-sm-down-flex {
        display: flex;
    }
}

@media only screen and (min-width: 770px) {
    .ion-hide-sm-up {
        display: block;
    }
    .ion-hide-sm-down {
        display: none;
    }
    .ion-hide-sm-down-flex {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 868px) {
    .item-col {
        flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    }
    .ion-hide-sm-up {
        display: block;
    }
    .ion-hide-sm-down {
        display: none;
    }
    .ion-hide-sm-down-flex {
        display: none;
    }

}

@media only screen and (max-width: 868px) {
    .ion-hide-sm-up {
        display: none;
    }
    .ion-hide-sm-down {
        display: block;
    }
    .ion-hide-sm-down-flex {
        display: flex;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .item-col {
        flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .item-col {
        flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    }
}

/* Extra large devices (large laptops and desktops, 1700px and up) */
@media only screen and (min-width: 1600px) {
    .item-col {
        flex: 0 0 calc(calc(2.5 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(2.5 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(2.5 / var(--ion-grid-columns, 12)) * 100%);
    }
}

/* Extra large devices (large laptops and desktops, 1700px and up) */
@media only screen and (min-width: 1700px) {
    .item-col {
        flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
    }
}

.image-container {
    border-radius: 10px;
    min-height: 200px;
    background-size: contain;
    //margin: 0.5rem !important;
    cursor: pointer;
    background-color: #f1eeee;
    background-repeat: no-repeat;
    background-position: center center;
}

.question-label {
    text-overflow: auto !important;
    white-space: normal !important;
}

.modal-content {
    --background: white;
}

.invalid-feedback {
    color: #f44336;
    font-size: 12px;
    margin-top: 5px;
}

.sso-button {
    --background: #2672ec;
}

ion-button ion-icon[slot="start"]{
    margin-right: 10px;
}

.custom-loading-spinner ~ .loading-wrapper{
    display: none !important;
}

.custom-loading {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    font-family: var(--ion-font-family, inherit);
    font-size: 14px;
    z-index: 99999999;
    .backdrop {
        background: #000;
        opacity: 0.32;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .loading-wrapper {
        background: white;
        padding: 24px;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
        width: auto;
        z-index: 10;
        border-radius: 2px;
        color: #262626;
    }
    .loading-content {
        margin-left: 16px;
    }
}

.question-modal {
    --width: 95%;
    --height: calc(100vh - 100px);
}
@media only screen and (min-width: 992px){
.split-pane-md > ion-menu {
    max-width: 300px!important;
  }
}

ion-menu {
    --ion-background-color: #f0eeee;
    border: none;
    .header-md::after {
        width: 0;
        height: 0;
    }
    ion-toolbar {
    --background: #f0eeee;
    }
    ion-content{
        --background: #f0eeee;
        ion-item{
            --background: #f0eeee;
            &:hover{
                --background: #d7d2d2;
            }
        }
    }
    ion-list {
    background: transparent;
    }

}

.flag-icon {
    position: absolute;
    left: 62px;
    bottom: 17px;
}

.flag-icon-menu {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1em !important;
}

.customLocaleSelect .alert-radio-label {
    padding-left: 73px !important;
}

.customLocaleSelect .alert-radio-label {
    padding-inline-start: 72px;
    padding-left: 73px !important;
}

.headerButton {
    --background: var(--ion-color-default) !important;
    --background-hover: var(--ion-color-default) !important;
    color: black !important;
    --color-focused: black !important;
    --color-activated: black !important;
    --background-activated: var(--ion-color-default) !important;
    --background-focused: var(--ion-color-default) !important;
}

.domainColorInButton{
    --background: var(--ion-color-primary) !important;
    color: white;
    --color: white;
    border-radius: 15px;
    font-size: 13px;
}
.commonColorInButton{
    --background: #b0b0b0  !important;
    color: black;
    --color: black;
    border-radius: 15px;
    font-size: 13px;
}

.domainRatingColor{
    color: var(--ion-color-primary);
    --color: var(--ion-color-primary);
}
.commonRatingColor{
    color: #b0b0b0;
    --color: #b0b0b0;
}


.ion-justify-content-start{justify-content:flex-start!important}
.ion-justify-content-center{justify-content:center!important}
.ion-justify-content-end{justify-content:flex-end!important}
.ion-justify-content-around{justify-content:space-around!important}
.ion-justify-content-between{justify-content:space-between!important}
.ion-justify-content-evenly{justify-content:space-evenly!important}
.ion-align-items-start{align-items:flex-start!important}
.ion-align-items-center{align-items:center!important}
.ion-align-items-end{align-items:flex-end!important}
.ion-align-items-stretch{align-items:stretch!important}
.ion-align-items-baseline{align-items:baseline!important}

ion-icon[name=help-circle-outline] {
    min-width: 27px;
}
.fixed-top-content {
    background: var(--ion-color-secondary);
    padding-left: 14px !important;
}

.userFullName {
  white-space: normal;
  display: block;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  text-align: end;
}
.alert-button-group.sc-ion-alert-md{
    flex-flow: row-reverse;
    place-content: end;
  }